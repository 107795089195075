<template>
  <div>
    <v-btn
      class="mx-2 button-add"
      small
      @click.stop.prevent="ModalAddTva('show')"
      title="Ajouter TVA"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="modalAddTva"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter TVA </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="ModalAddTva('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add" ref="AddTva">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Tva *"
                  v-model="valeur"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  :rules="[v => !!v || 'Tva est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col clos="6">
                <v-text-field
                  label="Compte tva vente"
                  v-model="compte_vente"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col clos="6">
                <v-text-field
                  label="Compte tva achat"
                  v-model="compte_achat"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col clos="6">
                <v-text-field
                  label="compte marchandise(HT) "
                  v-model="compte_marchandise"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col clos="6">
                <v-text-field
                  label="Compte prestation(HT) "
                  v-model="compte_prestation"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="margin-none">
              <v-col cols="2">
                <v-checkbox
                  label="Activer"
                  class="checked-tva margin-none"
                  color="#704ad1"
                  :value="1"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  v-model="is_active"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="errorTvaUnite" class="message-error-modal">
          <ul v-if="Array.isArray(errorTvaUnite)" class="mb-0">
            <li v-for="(e, index) in errorTvaUnite" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ errorTvaUnite }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="addTav()"
            :loading="loadingTvaUnite"
            :disabled="loadingTvaUnite"
            :class="{ loader: loadingTvaUnite }"
          >
            Ajouter
          </v-btn>
          <v-btn text @click="ModalAddTva('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  props: { filiale_id: { required: true } },
  data() {
    return {
      modalAddTva: false,
      errorTvaUnite: null,
      compte_vente: null,
      compte_achat: null,
      is_active: 1,
      loadingTvaUnite: false,
      valeur: null,
      compte_prestation: null,
      compte_marchandise: null
    };
  },
  methods: {
    ...mapActions(['addTva']),
    ModalAddTva(action) {
      if (action === 'show') {
        this.modalAddTva = true;
        this.is_active = 1;
      } else if (action === 'hide') {
        this.$refs.AddTva.reset();
        this.modalAddTva = false;
        this.is_active = 0;
        this.resteTvaUniteModal();
      }
    },
    async addTav() {
      if (this.$refs.AddTva.validate()) {
        this.errorTvaUnite = '';
        if (this.valeur > 100) {
          this.errorTvaUnite = 'tva doit etre inferieur à 100';
        } else {
          this.loadingTvaUnite = true;
          const response = await this.addTva({
            valeur: this.valeur,
            compte_vente: this.compte_vente,
            compte_achat: this.compte_achat,
            is_active: this.is_active,
            filiale_id: this.filiale_id,
            compte_prestation: this.compte_prestation,
            compte_marchandise: this.compte_marchandise
          });
          if (response.success) {
            this.$emit('addTva', response.data);
            this.ModalAddTva('hide');
            this.resteTvaUniteModal();
            this.loadingTvaUnite = false;
          } else {
            this.loadingTvaUnite = false;
            this.errorTvaUnite = response.response;
          }
        }
      }
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
      this.compte_vente = null;
      this.compte_achat = null;
      this.compte_prestation = null;
      this.compte_marchandise = null;
      // this.is_active = 1;
    }
  }
};
</script>
